import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import PropertyHistory from "@/components/PropertyHistory.vue";

jest.mock("@/services/MyRentalsConfig");

const defaultProps = {
  propertyUuid: "uuid",
  leasings: {
    items: [
      {
        lastStatusChangedAt: "2023-10-30T06:16:20.000000Z",
        leasingContractSignatureDate: null,
        leasingContractValidityDate: "2023-10-30T00:00:00.000000Z",
        leasingId: 3407,
        leasingTerminationDate: null,
        monthlyPrice: 100000,
        status: 2,
      },
    ],
  },
};

const defaultMocks = {
  $router: { push: jest.fn() },
};

const component = (data = {}, props = {}, mocks = defaultMocks) => {
  return myRentalsShallowMount(PropertyHistory, {
    data,
    props: { ...defaultProps, ...props },
    mocks,
    router: true,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("PropertyHistory", () => {
  testMyRentalsShallowMount(PropertyHistory, {
    props: defaultProps,
    mocks: defaultMocks,
    router: true,
  });

  describe("Computed", () => {
    describe("orderedHistoryWithReviewedSignatureDate", () => {
      it("should set leasingContractSignatureDate to null if leasingContractValidityDate is higher than leasingContractSignatureDate", () => {
        // given
        const mockedProps = {
          leasings: {
            items: [
              {
                lastStatusChangedAt: "2023-10-30T06:16:20.000000Z",
                leasingContractSignatureDate: "2023-10-30T00:00:00.000000Z",
                leasingContractValidityDate: "2023-10-26T00:00:00.000000Z",
                leasingId: 3407,
                leasingTerminationDate: null,
                monthlyPrice: 100000,
                status: 2,
              },
              {
                lastStatusChangedAt: "2023-10-30T06:16:20.000000Z",
                leasingContractSignatureDate: "2023-10-30T00:00:00.000000Z",
                leasingContractValidityDate: "2023-11-02T00:00:00.000000Z",
                leasingId: 3407,
                leasingTerminationDate: null,
                monthlyPrice: 100000,
                status: 2,
              },
            ],
          },
        };

        const expectedResults = [
          {
            lastStatusChangedAt: "2023-10-30T06:16:20.000000Z",
            leasingContractSignatureDate: null,
            leasingContractValidityDate: "2023-10-26T00:00:00.000000Z",
            leasingId: 3407,
            leasingTerminationDate: null,
            monthlyPrice: 100000,
            status: 2,
          },
          {
            lastStatusChangedAt: "2023-10-30T06:16:20.000000Z",
            leasingContractSignatureDate: "2023-10-30T00:00:00.000000Z",
            leasingContractValidityDate: "2023-11-02T00:00:00.000000Z",
            leasingId: 3407,
            leasingTerminationDate: null,
            monthlyPrice: 100000,
            status: 2,
          },
        ];
        const wrapper = component({}, mockedProps);
        // when
        //then
        expect(wrapper.vm.orderedHistoryWithReviewedSignatureDate).toEqual(
          expectedResults
        );
      });
    });
  });
});
