
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRentalLeasing, IRentalLeasings } from "@/models/RentalLeasing";
import DateUtils from "@/services/utils/DateUtils";
import { HousfyButton, HousfyAlertMessage } from "housfy-ui-lib";
import { resolveRoutePath } from "@/router";

@Component({
  name: "PropertyHistory",
  components: {
    HousfyButton,
    HousfyAlertMessage,
  },
})
export default class PropertyHistory extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;
  @Prop({ type: Object, default: () => {} })
  leasings!: IRentalLeasings;

  get orderedLeasingsByTerminationDate(): IRentalLeasing[] {
    return this.leasings.items?.sort((a, b) => {
      const dateA = a?.leasingTerminationDate
        ? new Date(a?.leasingTerminationDate).getTime()
        : null;
      const dateB = b?.leasingTerminationDate
        ? new Date(b?.leasingTerminationDate).getTime()
        : null;

      if (dateA === dateB) {
        return 0;
      }
      // nulls sort before anything else (top of the list)
      if (dateA === null) {
        return -1;
      }
      if (dateB === null) {
        return 1;
      }
      return dateA < dateB ? 1 : -1;
    });
  }

  get orderedHistoryWithReviewedSignatureDate(): IRentalLeasing[] {
    const historyWithCorrectDates = this.orderedLeasingsByTerminationDate.map(
      (item) => {
        const validityDate = item.leasingContractValidityDate
          ? new Date(item.leasingContractValidityDate).getTime()
          : undefined;

        const signatureDate = item.leasingContractSignatureDate
          ? new Date(item.leasingContractSignatureDate).getTime()
          : undefined;

        if (signatureDate && validityDate && validityDate < signatureDate)
          item.leasingContractSignatureDate = null;

        return item;
      }
    );
    return historyWithCorrectDates;
  }

  formatDate(stringDate: string) {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }

  handleSeeDocumentsButtonClick(leasingId: string) {
    const route = resolveRoutePath({
      name: "documentsLeasing",
      params: {
        propertyUuid: this.propertyUuid,
        leasingId: leasingId,
      },
    });
    this.$router.push(route);
  }
}
